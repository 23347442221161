<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="关键字搜索">
	          <el-input v-model="filters.registerType" width="120px"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="filters.areaStr" placeholder="启用状态" filterable clearable>
              <el-option
                  v-for="item in areaArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
	        <el-form-item label="">
		        <el-select v-model="filters.register" placeholder="职业等级方向" filterable clearable>
			        <el-option
					        v-for="item in registers"
					        :key="item.id"
					        :label="item.remark"
					        :value="item.id"
			        >
			        </el-option>
		        </el-select>
	        </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
          <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
          <el-table-column prop="pointCode" label="鉴定点ID"  width='100' align="center" ></el-table-column>
          <el-table-column prop="questionTypeCode" label="题目ID"  width='100' align="center" ></el-table-column>
	        <el-table-column prop="questionCode" label="试题ID"  width='110' align="center" ></el-table-column>
          <el-table-column prop="remark" label="职业等级方向" width='180' align="center" ></el-table-column>
	        <el-table-column prop="ddIdExamVersionValue" label="版本号" width="100"  align="center" ></el-table-column>
          <el-table-column prop="pointName" label="鉴定点名称"  align="center" ></el-table-column>
          <el-table-column prop="questionContent" label="问题描述"  align="center" ></el-table-column>
          <el-table-column prop="score" label="分值" width='80' align="center" ></el-table-column>
          <el-table-column prop="ddIDQuestionType" width='100' label="是否变体"  align="center" >
            <template #default='scope'>
              <el-tag v-if='scope.row.ddIdQuestionType === 2' type='success'>是</el-tag>
              <el-tag v-else type='warning'>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="isBindingVideo" label="视频解析" width='120' align="center" >
            <template #default='scope'>
              <el-tag v-if='scope.row.isBindingVideo' type='success'>已上传</el-tag>
              <el-tag v-else type='warning'>未上传</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="ddCommonStatus" label="状态" width='100' align="center" >
            <template #default='scope'>
              <el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
              <el-tag v-else type='warning'>禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="fileUrl" label="操作" width='120' align="center" >
            <template #default='scope'>
              <el-button type="text" @click="preview(scope.row)">{{'预览'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <el-dialog
        :title= "editFalse?'编辑':'添加题干'"
        :visible.sync="dialogs.addType.dialog"
        width="55%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="140px">
        <el-form-item label="职业等级方向：">
          <el-select v-model="addForm.oproomProfessionDirId" @change="changeVal" style="width: 80%" placeholder="请选择职业等级方向" filterable clearable>
            <el-option
                v-for="item in professionList"
                :key="item.id"
                :label="item.remark"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="鉴定点：">
          <el-select v-model="addForm.pointCode" @change="changepointCode" style="width: 80%" placeholder="请选择鉴定点" filterable clearable>
            <el-option
                v-for="item in pointNums"
                :key="item.id"
                :label="item.pointName"
                :value="item.pointCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="鉴定点ID：" >
          <el-input style="width: 80%" disabled v-model="addForm.pointCode" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="题目ID：" >
          <el-input style="width: 80%"  v-model="addForm.questionTypeCode" readonly placeholder="不可输入"></el-input>
        </el-form-item>
	      <el-form-item label="试题ID：" >
		      <el-input style="width: 80%" v-model="addForm.questionCode" placeholder=""></el-input>
	      </el-form-item>
        <el-form-item label="配分：">
          <el-input style="width: 80%" v-model="addForm.score" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label='是否变体：' prop='webUrl'>
          <template>
            <el-radio-group v-model="addForm.ddIdQuestionType">
              <el-radio :label="2">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item label="题干：">
          <el-input
              type="textarea"
              :rows="4"
              style="width: 80%"
              placeholder="请输入内容"
              v-model="addForm.questionContent">
          </el-input>
        </el-form-item>
        <el-form-item prop="fileId" label="教学视频：">
	        <template>
		        <div class="hello">
			        <div>
				        <input type="file" class="upload" @change="doUpload" ref="inputer" multiple />
				        <!--								<el-button type="primary" size="small" @click="startAll">全部开始</el-button>-->
				        <!--								<el-button type="warning" size="small" @click="pauseAll">全部暂停</el-button>-->
				        <!--								<el-button type="danger" size="small"  @click="clearAll">全部删除</el-button>-->
			        </div>
			        
			        <div>
				        <el-table :data="tableData1" border style="width: 100%">
<!--					        <el-table-column prop="id" label="ID" width="180">-->
<!--					        </el-table-column>-->
					        <el-table-column prop="fileName" label="文件名" width="">
					        </el-table-column>
					        <el-table-column prop="size" label="文件大小" width="120">
						        <template slot-scope="scope">{{ transformSize(scope.row.size)}}</template>
					        </el-table-column>
					        <el-table-column prop="progress" label="进度" width="180">
						        <template slot-scope="scope">
							        <el-progress :text-inside="true" :stroke-width="26" :percentage="scope.row.progress"></el-progress>
						        </template>
					        
					        </el-table-column>
					        <el-table-column prop="progress" label="操作" width="160">
						        <template slot-scope="scope">
							        <el-button type="text" size="small" @click="start(scope.row.id)">开始</el-button>
							        <el-button type="text" size="small" @click="stop(scope.row.id)">暂停</el-button>
							        <el-button type="text" size="small" @click="remove(scope.row.id)">删除</el-button>
						        </template>
					        </el-table-column>
				        </el-table>
			        </div>
		        </div>
	        </template>
        </el-form-item>
      </el-form>
      <span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
      <span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
    <el-dialog
        title= "提示"
        :visible.sync="dialogs.addType.confirm"
        width="30%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="100px">
        <el-form-item label="职业功能：">
          {{'该分类下的数据将停止使用”，确定禁用吗？'}}
        </el-form-item>
      </el-form>
      <span  slot="footer" class="dialog-footer">
          <el-button @click="dialogs.addType.confirm = false">取 消</el-button>
          <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title= "预览"
        :visible.sync="dialogs.addType.firurl"
        width="50%"
        :show-close = false
    >
<!--      <iframe :src="videoFile"></iframe>-->

<!--      <video controls width="100%" :src="videoFile">-->
<!--        &lt;!&ndash;          <source :src="videoFile" type="video/mp4">&ndash;&gt;-->
<!--        &lt;!&ndash;            <source src="https://vdept3.bdstatic.com/mda-qd8h47v4wemqewex/sc/cae_h264/1712666300875566676/mda-qd8h47v4wemqewex.mp4?v_from_s=hkapp-haokan-hbf&auth_key=1712749130-0-0-75efb69ddd9d91c9e59159b32ad10527&bcevod_channel=searchbox_feed&pd=1&cr=2&cd=0&pt=3&logid=2330032235&vid=16910047636772584045&klogid=2330032235&abtest=" type="video/mp4">&ndash;&gt;-->
<!--      </video>-->
	    <template >
		    <div id="player"></div>
	    </template>
	    <span  slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="closePreview" :loading="loading">关闭</el-button>
	    </span>
    </el-dialog>
	  <el-dialog :visible.sync="dialogVisible" :width="'50%'">
      <template >
       <div id="preview"></div>
      </template>
		  
	  </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	uploadVideoFile,
	getAreaDictionaryList,
	getExamQuestionList,
	getOproomProfessionDirList,
	getOproomProfessionFuncList,
	updateOproomProfessionDir,
	getPointListByProDir, addExamQuestions, updateExamQuestion, addVideoByCode, getVideoUploadParams
} from '../../api/api'
import PlvVideoUpload from "@polyv/vod-upload-js-sdk";

export default {
  components:{Toolbar},
  data() {
    return {
      buttonList:[],
      tableData: [],
      tableData1: [],
	    registerType:[],
      areaArr: [
	      {name:'禁用',id:2},
	      {name:'启用',id:1}
      ],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
	      registerType: undefined,
        areaStr: undefined,
      },
      dialogs:{
        addType:{
          dialog:false,
          confirm:false,
          firurl:false,
        },
        confirm:false,
      },
      addForm:{
	      questionId:undefined,
        oproomProfessionDirId:undefined,//职业等级方向ID
        pointCode:undefined,//鉴定点ID
        questionCode:undefined,//题干ID
        score:undefined,//配分
        ddIdQuestionType:undefined,//是否变体
        questionContent:undefined,//题干内容
	      videoManageId:undefined,//视频ID
        id:undefined,
      },
      loading:false,
      professionType: [],
      editFalse:false,
      videoFile:undefined,
      professionList:[],
      pointNums:[],
	    dialogImageUrl:undefined,
	    dialogVisible:false,
	    fileList:undefined,
	    uploadFile:undefined,
	    uploadList:undefined,
	    vodPlayerJs: "https://player.polyv.net/resp/vod-player/latest/player.js",
	    // vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
	    previewBlooean:false,
	    fileSetting: {
		    desc: '测试视频上传', // 描述
		    cataid: 1713764283001, // 分类ID 可以后端传递 也可以不写 或写死
		    tag: 'tag', // 标签
		    luping: 0, // 是否开启视频课件优化处理，对于上传录屏类视频清晰度有所优化：0为不开启，1为开启
		    keepsource: 1 // 是否源文件播放（不对视频进行编码）：0为编码，1为不编码
	    },
	    userid:undefined,
	    
	    videoUpload: null, // 视频上传实例
	    // userid: '',//从点播后台查看获取
	    ptime: '', // 当前时间戳
	    videoId:undefined,
	    registers:[],

    }
  },
  methods: {
	  closePreview(){
		  // 清空 #player 所在的 div
		  const playerDiv = document.querySelector("#player");
		  const preViewDiv = document.querySelector("#preview");
		  if (playerDiv) {
			  playerDiv.innerHTML = '';
		  }
		  if (preViewDiv){
			  preViewDiv.innerHTML = '';
		  }
			this.onPause()
		  this.dialogs.addType.firurl = false
	  },
	  start(uploaderid) {// 单个上传
		  console.log(uploaderid)
		  this.videoUpload.resumeFile(uploaderid);
	  },
	  stop(uploaderid) {// 单个暂停
		  console.log(uploaderid)
		  this.videoUpload.stopFile(uploaderid);
	  },
	  remove(uploaderid) {// 单个删除
		  console.log(uploaderid)
		  this.videoUpload.removeFile(uploaderid);
		  this.tableData1 = this.tableData1.filter((item) => item.id !== uploaderid)
	  },
	  startAll() {// 全部上传
		  if (this.videoUpload) {
			  this.videoUpload.startAll();
		  }
	  },
	  pauseAll() {// 全部暂停
		  if (this.videoUpload) {
			  this.videoUpload.stopAll();
		  }
	  },
	  clearAll() {// 全部删除
		  if (this.videoUpload) {
			  this.videoUpload.clearAll();
			  this.tableData1 = []
			  this.$refs.inputer.value =''
		  }
	  },
	  doUpload() {// 选择文件
			this.getUserData()
		  let inputDOM = this.$refs.inputer; // 通过DOM取文件数据
		  console.log(inputDOM.files)
		  if ([...inputDOM.files].length > 0) {
			  [...inputDOM.files].forEach((file, index, arr) => {
				  let fileSetting = { // 文件上传相关信息设置
					  title: file.name, // 标题
					  desc: 'jssdk插件上传', // 描述
					  cataid: this.fileSetting.cataid, // 上传分类目录ID
					  tag: '', // 标签
					  luping: 0, // 是否开启视频课件优化处理，对于上传录屏类视频清晰度有所优化：0为不开启，1为开启
					  keepsource: 0, // 是否源文件播放（不对视频进行编码）：0为编码，1为不编码
					  state: '' //用户自定义信息，如果提交了该字段，会在服务端上传完成回调时透传返回。
				  }
				  let uploadManager = this.videoUpload.addFile(
						  file, // file 为待上传的文件对象
						  {
							  FileStarted: this.onFileStarted,// 文件开始上传回调
							  FileProgress: this.onFileProgress,// 文件上传中回调
							  FileSucceed: this.onFileSucceed,// 文件上传成功回调
							  FileFailed: this.onFileFailed,// 文件上传失败回调
							  FileStopped: this.onFileStopped,// 文件上传停止回调
						  },
						  fileSetting
				  );
				  this.addTableData(uploadManager)
			  })
		  }
	  },
	  onFileStarted(data) {
		  console.log("文件上传开始: ", data);
		  this.tableData1.filter((item) => item.id === data.uploaderid)[0].progress = 0
	  },
	  onFileProgress(data) {
		  let p = parseInt(data.progress * 100);// 上传的进度条
		  console.log("文件上传中: ", data);
		  this.tableData1.filter((item) => item.id === data.uploaderid)[0].progress = p
		  
	  },
	  onFileSucceed(data) {
		  console.log("文件上传成功: ", data);
		  let params = {
			  fileName:data.fileData.filename,
			  fileSize:data.fileData.size,
			  videoCode:data.fileData.vid,
		  }
		  addVideoByCode(params).then(res=>{
			  console.log(res,'deddddd')
			  if (res.data.success){
				  this.videoId = res.data.response.id
			  }
		  })
	  },
	  onFileFailed(data) {
		  console.log("文件上传失败: ", data);
	  },
	  onFileStopped(data) {
		  console.log("文件上传停止: ", data);
	  },
	  addTableData(data) { // 增加表格数据
		  let obj = {
			  id: data.id,
			  fileName: data.fileData.title,
			  size: data.fileData.size,
			  progress: 0
		  }
		  if (this.tableData1&&this.tableData1.length<1){
			  this.tableData1.push(obj)
		  }else {
			  this.$message({
				  message:"视频只能添加一条！",
				  type:'warning'
			  })
		  }
	  },
	  // autoUpdateUserData(timer, videoUpload) { // 启动获取用户信息
		//   this.getUserData(videoUpload);
		//   if (timer) {
		// 	  clearTimeout(timer);
		// 	  timer = null;
		//   }
		//   timer = setTimeout(() => {
		// 	  this.autoUpdateUserData(timer, videoUpload);
		//   }, 3 * 60 * 1000);
	  // },
	  getUserData() { // 获取用户详细信息
		  // this.ptime = new Date().getTime()
		  let params = {}
		  getVideoUploadParams(params).then(res=>{
			  console.log(res,'kjklsjdfk')
			  if (res.data.success){
				  const hash = res.data.response.hash
				  const sign = res.data.response.sign
				  const userid = (res.data.response.userId)
				  const ptime = parseInt(res.data.response.ts)
				  let userData = {
					  userid: userid,
					  ptime: ptime,
					  sign: sign,
					  hash: hash
				  };
				  this.videoUpload.updateUserData(userData);
			  }
		  })
		  
	  },
	  transformSize(bytes) {// 文件大小转换
		  const bt = parseInt(bytes);
		  let result;
		  if (bt === 0) {
			  result = '0B';
		  } else {
			  const k = 1024;
			  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			  const i = Math.floor(Math.log(bt) / Math.log(k));
			  if (typeof i !== 'number') {
				  result = '-';
			  } else {
				  result = (bt / Math.pow(k, i)).toFixed(2) + sizes[i];
			  }
		  }
		  return result;
	  },
		
		
	  handle_success (res) {
		  // console.log(res)
		  if (res.success) {
			  console.log(res,'successRes')
			  this.addForm.videoManageId =res.response.id
		  }
	  },
	  handleRemove(file, fileList) {
		  this.fileList = fileList
		  // console.log(this.fileList,'2222')
	  },
	  handlePreview(file) {
		  // console.log(file,'111');
		  this.previewBlooean = true;
		  this.loadPlayerScript(this.loadPlayer);
		  if (this.player) {
			  this.onPause();//两个监听事件
			  this.onPlay();
			  this.onPreview()
		  }
		  this.dialogVisible = true;
	  },
	  handleChange(file, fileList) {
		  this.fileList=fileList
		  // console.log(this.fileList,'33333')
	  },
	  
	  
	  splitQuestionId(val){
		  // console.log(1123332)
			if (val){
				this.addForm.questionTypeCode = val.substring(0,8)
			}
	  },
    changepointCode(val){
      const obj = this.pointNums.filter(item=>item.pointCode === val)
      console.log(obj,'obj')
      this.pointId = obj[0].id
    },
    handleAdd(){
			this.tableData1 = []
      this.addForm={
        oproomProfessionDirId:undefined,//职业等级方向ID
        pointCode:undefined,//鉴定点ID
        questionCode:undefined,//题干ID
        score:undefined,//配分
        ddIdQuestionType:undefined,//是否变体
        questionContent:undefined,//题干内容
	      videoManageId:undefined,//视频ID
        id:undefined,
      }
      let params = {
        pageIndex:1,
        pageSize:20,
	      ddCommonStatus: 1,
      }
      getOproomProfessionDirList(params).then(res=>{
        if (res.data.success){
          console.log(res.data.response,'response1111')
          this.professionList = res.data.response.data
        }
      })
      this.editFalse = false
      this.dialogs.addType.dialog = true;
    },
    changeVal(val){
      console.log(val,'val112222')
      let params = {
        oproomProfessionDirId:val,
      }
      getPointListByProDir(params).then(res=>{
        if (res.data.success){
          this.pointNums = res.data.response
        }
      })
    },
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    getDataList(){
      this.pages = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.getListData()
    },
    // 列表查询接口
    getListData(){
      var params = {
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        keyWord:this.filters.registerType,//关键字查询
        examQuestionStatus:this.filters.areaStr,//启用状态
	      oproomProfessionDirId:this.filters.register,//职业等级方向
      }
      this.listLoading = true
      getExamQuestionList(params).then(res => {
        var result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
        }
        this.listLoading = false
      })
    },
    //预览
    preview(row){
			this.needData = row
	    if (row.playSafeCode === null){
		    this.$message({
			    message:'视频正在审核中，请稍后再试！',
			    type:'warning'
		    })
		    return;
	    }
	    this.loadPlayerScript(this.loadPlayer);
	    if (this.player) {
		    this.onPause();//两个监听事件
		    this.onPlay();
		    this.onPreview()
	    }
      this.dialogs.addType.firurl = true;
    },
    async addConfirm(){
      let params = {
        ...this.addForm,
        pointId:this.pointId,
	      videoManageId:this.videoId,
      }
      const res = await addExamQuestions(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
	  
	  loadPlayerScript(callback) {
			
		  if (!window.polyvPlayer) {
			  const myScript = document.createElement("script");
			  myScript.setAttribute("src", this.vodPlayerJs);
			  myScript.onload = callback;
			  document.body.appendChild(myScript);
		  } else {
			  callback();
		  }
	  },
	  // 暂停事件
	  onPause() {
		  let that=this;

		  this.player.on("s2j_onVideoPause", function (e) {
			  that.flag=true;
		  });
	  },
	  // 播放事件
	  onPlay() {
		  const _that = this;

		  this.player.on("s2j_onVideoPlay", function (e) {
			  _that.flag=false;
		  });
	  },
	  //预览时长
	  onPreview(){
		  this.player.on('s2j_onPlayStart', () => { //订阅视频开始播放事件
			  console.info('视频开始播放');
			  // this.player.j2s_seekVideo(60); //视频seek到第60秒
		  });
	  },
	  
	  loadPlayer() {
		  // 清空 #player 所在的 div
		  const playerDiv = document.querySelector("#player");
		  const preViewDiv = document.querySelector("#preview");
		  if (playerDiv) {
			  playerDiv.innerHTML = '';
		  }
		  if (preViewDiv){
			  preViewDiv.innerHTML = '';
		  }
		  const polyvPlayer = window.polyvPlayer;
		  
		  this.player = polyvPlayer({
			  wrap: this.previewBlooean?'#preview':"#player" ,
			  width: "100%",
			  height: "544",
			  vid: this.needData.fileCode,
			  autoplay: false,
			  // ban_preview_video: "on",
			  // allowFullscreen:false,
			  code:'off',
			  playsafe:this.needData.playSafeCode,
		  });
			this.previewBlooean = false ;
	  },
	  
	  handleEdit(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
		  this.tableData1 = []
		  this.fileList = [];
		  this.editFalse = true;
		  if (this.needData&&this.needData.videoManageId){
			  let obj = {
				  id: this.needData.videoManageId,
				  fileName: this.needData.fileName,
				  size: this.needData.fileSize,
				  progress: 100
			  }
			  this.tableData1.push(obj)
		  }
	    let params1 = {
		    pageIndex:1,
		    pageSize:20,
		    ddCommonStatus: 1,
	    }
	    getOproomProfessionDirList(params1).then(res=>{
		    if (res.data.success){
			    console.log(res.data.response,'response1111')
			    this.professionList = res.data.response.data
		    }
	    })
      let params = {
        oproomProfessionDirId:this.needData.oproomProfessionDirId,
      }
      getPointListByProDir(params).then(res=>{
        if (res.data.success){
          this.pointNums = res.data.response
        }
      })
		 
		 
      this.addForm = {
        ...this.needData,
      }
			if (this.needData.fileCode){
				this.fileList.push({
					vid:this.needData.fileCode,
					playSafe:this.needData.playSafeCode,
					name:this.needData.fileName,
					file:this.needData.fileUrl
				})
			}
      this.dialogs.addType.dialog = true;
    },
    showConfirm() {
      if (this.addForm.radioAdd === 2){
        this.dialogs.addType.confirm = true
      }else {
        this.editConfirm()
      }
    },
    async editConfirm(){
      let params = {
        ...this.addForm,
        id:this.needData.id,
	      videoManageId:this.videoId,
      }
      const res = await updateExamQuestion(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.dialogs.addType.confirm = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
    //禁用
    async disable(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.addForm = {
        ...this.needData,
      }
      let params = {
        ...this.addForm,
        ddCommonStatus:2,//状态，1:启用;2:禁用
      }
      const res = await updateExamQuestion(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }

    },
	  //启用
	  async addAnother(){
		  if (!this.needData){
			  this.$message({
				  message:'请选择一条数据',
				  type:'warning'
			  })
			  return;
		  }
		  this.addForm = {
			  ...this.needData,
		  }
		  let params = {
			  ...this.addForm,
			  ddCommonStatus:1,//状态，1:启用;2:禁用
		  }
		  const res = await updateExamQuestion(params)
		  if (res.data.success){
			  this.getDataList()
			  this.dialogs.addType.dialog = false ;
			  this.$message({
				  message:res.data.message,
				  type:'success'
			  })
		  }else{
			  this.$message({
				  message:res.data.message,
				  type:'warning'
			  })
		  }
		  
	  },




    // // 获取省
    // getAreaList() {
    //   var params = {
    //     parentId: 1,
    //   }
    //   getAreaDictionaryList(params).then(res => {
    //     if (res.data.success) {
    //       this.areaArr = res.data.response.reverse();
    //     }
    //   })
    // },
    //获取字符空间
    async getNamespace(){
      let params = {
        pageIndex:undefined,
        pageSize:undefined,
      }
      const res = await  getByNamespace(params)
      console.log(res,'res')
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      console.log(val,'val')
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getListData();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getListData();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {
	  'addForm.questionCode': {
		  handler(newQuestionCode, oldQuestionCode) {
			  // 调用 splitQuestionId 方法并更新 addForm.questionTypeCode
			  this.splitQuestionId(this.addForm.questionCode);
		  },
		  immediate: true, // 立即执行一次以确保初始值也被处理
	  },
  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
		this.uploadFile = uploadVideoFile
	  this.videoUpload = new PlvVideoUpload({
		  region: 'line1', // (可选)上传线路, 默认line1
		  events: {
			  Error: (err) => { // 错误事件回调
				  console.log(err);
				  let errMag = `（错误代码：${err.code}）${err.message}`;
				  this.$alert(errMag, '标题名称', {
					  confirmButtonText: '确定',
					  type: 'error',
				  });
			  },
			  UploadComplete: () => { // 全部上传任务完成回调
				  console.info('上传结束：', this.videoUpload);
				  console.log(this.tableData)
				  this.$message({
					  message: '全部上传任务完成',
					  type: 'success'
				  });
			  }
		  }
	  });
	  //职业等级方向
	  let params2 = {
		  pageIndex:1,
		  pageSize:20,
		  ddCommonStatus:1,
	  }
	  getOproomProfessionDirList(params2).then(res2=>{
		  if (res2.data.success){
			  this.registers = res2.data.response.data
		  }
	  })
    this.getListData();
    // this.getAreaList()
  },
	computed:{
		
		headers(){
			return {
				"Authorization": 'Bearer ' + localStorage.getItem("Token")
			}
		}
		
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	mounted() {
		// this.autoUpdateUserData(null, this.videoUpload);
	},
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
